import './styles.scss'

import { Media } from 'gatsby-plugin-fresnel'
import { useAtom } from 'jotai'
import Cookies from 'js-cookie'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Seo } from '@/components'

import { Atom } from '../../atom'

type pageContext = {
  data: PDFContext[]
  titleEn: string
  titlePtBr: string
}

type PDFContext = {
  pdfNameEn: string
  pdfNamePtBr: string
  yearOfPdf: string
  pdfFile: Media
}

type Media = {
  mediaItemUrl: string
}

type PDF = {
  year: string
  data: Content[]
}

type Content = {
  name: string
  link: string
}

const FinancialStatement = ({ pageContext }: { pageContext: pageContext }) => {
  const { i18n } = useTranslation()

  const isShowEnglish = (language: string) => {
    return language !== 'pt' && language !== 'pt-BR'
  }

  const convertPdfPath = (src: string) => {
    if (
      src &&
      src.startsWith(`${process.env.CONTENT_HUB_ENDPOINT}/wp-content/uploads`)
    ) {
      return src.replace(
        `${process.env.CONTENT_HUB_ENDPOINT}/wp-content/uploads`,
        `${process.env.GATSBY_WORDPRESS_CACHE_BASE}/wp_media`
      )
    }
    return src
  }

  const getData = (currentLang: string) => {
    const data = pageContext.data as PDFContext[]
    const result = [] as PDF[]
    data.forEach((item) => {
      const yearIndex = result.findIndex((y) => y.year === item.yearOfPdf)
      const requiredName = isShowEnglish(currentLang)
        ? item.pdfNameEn
        : item.pdfNamePtBr
      if (yearIndex === -1) {
        result.push({
          year: item.yearOfPdf,
          data: [
            {
              name: requiredName,
              link: convertPdfPath(item.pdfFile.mediaItemUrl),
            },
          ],
        })
      } else {
        result[yearIndex].data.push({
          name: requiredName,
          link: convertPdfPath(item.pdfFile.mediaItemUrl),
        })
      }
    })
    return result
  }

  const [files, setFiles] = useState<PDF[]>(
    getData(Cookies.get('locale') || i18n.language)
  )

  const [lang, setLang] = useAtom(Atom.lang)

  useLayoutEffect(() => {
    const l = Cookies.get('locale')
    if (l) {
      setLang(l)
    } else {
      setLang('pt-BR') // default
    }
  }, [])

  useEffect(() => {
    setFiles(getData(lang))
  }, [lang])

  const renderDesktopYearCard = (year: string, data: Content[]) => {
    return (
      <div className="card">
        <span className="year">{year}</span>
        <div className="pdf-list">
          {data.map((item, index) => {
            return (
              <div key={item.name}>
                {index !== 0 && <hr />}
                {renderPdfFile(item.name, item.link)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderMobileYearCard = (year: string, data: Content[]) => {
    return (
      <div className="card">
        <div className="year-mobile">
          <span className="year">{year}</span>
          <hr />
        </div>
        <div className="pdf-list">
          {data.map((item, index) => {
            return (
              <div
                key={item.name}
                className={`${
                  index === data.length - 1 ? 'mobile-single-pdf' : 'mobile-pdf'
                }`}
              >
                {renderPdfFile(item.name, item.link)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderPdfFile = (name: string, link: string) => {
    return (
      <a className="pdf-container" href={link} target="_blank" rel="noreferrer">
        <div className="pdf-image">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.34283 1.84283C5.26059 1.92507 5.25 1.99392 5.25 2V22C5.25 22.0061 5.26059 22.0749 5.34283 22.1572C5.42507 22.2394 5.49392 22.25 5.5 22.25H19.5C19.5794 22.25 19.6346 22.2209 19.6767 22.1758C19.7258 22.1232 19.75 22.0546 19.75 22V8C19.75 7.58579 20.0858 7.25 20.5 7.25C20.9142 7.25 21.25 7.58579 21.25 8V22C21.25 22.8646 20.5621 23.75 19.5 23.75H5.5C5.00608 23.75 4.57493 23.5106 4.28217 23.2178C3.98941 22.9251 3.75 22.4939 3.75 22V2C3.75 1.50608 3.98941 1.07493 4.28217 0.78217C4.57493 0.48941 5.00608 0.25 5.5 0.25H13.5C13.9142 0.25 14.25 0.585786 14.25 1C14.25 1.41421 13.9142 1.75 13.5 1.75H5.5C5.49392 1.75 5.42507 1.76059 5.34283 1.84283Z"
              fill="#A0A9BE"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.213 0.307112C13.4932 0.191027 13.8158 0.255193 14.0303 0.469692L21.0303 7.46969C21.2448 7.68419 21.309 8.00678 21.1929 8.28703C21.0768 8.56729 20.8033 8.75002 20.5 8.75002H13.5C13.0858 8.75002 12.75 8.41424 12.75 8.00002V1.00002C12.75 0.696675 12.9327 0.423198 13.213 0.307112ZM14.25 2.81068V7.25002H18.6893L14.25 2.81068Z"
              fill="#A0A9BE"
            />
          </svg>
        </div>
        <span>{name}</span>
      </a>
    )
  }

  const getTitle = () => {
    if (isShowEnglish(lang)) {
      return pageContext.titleEn
    } else {
      return pageContext.titlePtBr
    }
  }

  return (
    <>
      <Seo
        title="Crypto.com | Securely Buy, Sell & Trade Bitcoin, Ethereum and 350+ Altcoins"
        description="Over 100 million users buy, sell, and trade Bitcoin, Ethereum, NFTs and more on Crypto.com. Join the World's leading crypto trading platform."
        link={'/br/regulatory-reports'}
        customMetaLink={'https://crypto.com/br/regulatory-reports'}
        lang={lang}
      />
      <div className="financial-statement-container">
        <h1>{getTitle()}</h1>
        <Media lessThan="md">
          {files.map((item, index) => {
            return (
              <div
                key={item.year}
                className={`${index !== 0 ? 'card-margin-top' : ''}`}
              >
                {renderMobileYearCard(item.year, item.data)}
              </div>
            )
          })}
        </Media>
        <Media greaterThanOrEqual="md">
          {files.map((item, index) => {
            return (
              <div
                key={item.year}
                className={`${index !== 0 ? 'card-margin-top' : ''}`}
              >
                {renderDesktopYearCard(item.year, item.data)}
              </div>
            )
          })}
        </Media>
      </div>
    </>
  )
}

export default FinancialStatement
